import { Form, useFetcher, useNavigation, useParams } from "react-router-dom";

import Button from "components/Button";
import CouponCodeInput from "v2/components/billing/CouponCodeInput";
import SubmitButton from "components/SubmitButton";

import { useActiveCoupons } from "v2/hooks/billing/useActiveCoupons";
import { useDiscountPrice } from "v2/hooks/billing/useDiscountPrice";
import { usePlans } from "v2/hooks/billing/usePlans";

import { BillingActions } from "v2/dataroutes/BillingData";
import { useUser } from "routes/dataroutes/UserData";

import Modal, {
  ModalButtonsSection,
  ModalContent,
  ModalSections
} from "./index";

import { fmtCurrency } from "utils";
import getDataFromSuccessfulResponse from "utils/getDataFromSuccessfulResponse";
import { isAnnualPlan } from "utils/planUtils";

import { Coupon, CouponError } from "types/Coupons";


export default function OldUpgradeSubscriptionModal() {
  const { planId } = useParams() as { planId: string };

  const navigation = useNavigation();

  const user = useUser();
  const { allPlans } = usePlans();
  const { activeCoupons } = useActiveCoupons();
  const couponFetcher = useFetcher<Coupon | CouponError | undefined>();

  const targetPlan = allPlans?.find(p => p.planSlug === planId);
  const discountPrice = useDiscountPrice({
    useScheduledSubscriptionIfAny: false,  // not checking here if other plans have already been scheduled or not... if one upgrades manually they should always get their target plans
    coupon: getDataFromSuccessfulResponse<Coupon>(couponFetcher.data),
    targetPlan: targetPlan
  });


  // <CouponCodeInput> will add the target plan id as a hidden input
  return (
    <Modal
      headline="Upgrade Subscription"
      preventClosingIf={ navigation.state !== "idle" }
    >
      { ({ closeModal }) =>
        <Form method="POST" action="/v2/billing">
          <ModalContent>
            <ModalSections>
              <div>
                Are you sure you want to upgrade your subscription to{ " " }
                <span className="font-bold capitalize">
                { targetPlan?.planName }
              </span>
                ?
              </div>
              <div>
                By upgrading your subscription, we will reset your credit
                counters, Invoice{ " " }
                <span className="font-bold">
                { fmtCurrency(discountPrice / 100) }{ " " }
              </span>{ " " }
                now and set your billing date to today.
              </div>
              {/* TODO it is hardcoded here that we don't allow coupons for annual subscriptions. should we change it and let Chargebee make that decision? */}
              { (user?.canUseAllCoupons || (user?.canUseCoupons && !isAnnualPlan(planId))) && (
                <CouponCodeInput
                  targetPlanSlug={ planId }
                  fetcher={ couponFetcher }
                />
              ) }
              { ((user?.canUseAllCoupons === false) && isAnnualPlan(planId) && ((activeCoupons?.length || 0) > 0)) && (
                <div>
                  Your current extra discount coupons will also be removed after the upgrade.
                </div>
              ) }
            </ModalSections>
            <ModalButtonsSection>
              <Button text="Cancel" className="button button-secondary" onClick={ closeModal } size="MD"/>
              <SubmitButton
                text="Upgrade Subscription"
                className="button button-primary"
                size="MD"
                checkFormValidity
                { ...BillingActions.upgrade }
              />
            </ModalButtonsSection>
          </ModalContent>
        </Form>
      }
    </Modal>
  );
};

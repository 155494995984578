import { useCallback } from "react";
import { Transition } from "@headlessui/react";
import { RiFileCopyLine } from "@remixicon/react";

import DecoratedInputField from "components/InputFieldComponents/DecoratedInputField";
import Tag from "components/Tag";

import { useCopyToClipboard } from "hooks/useCopyToClipboard";

import { FAST_LINEAR_TRANSITION } from "misc/transitionProperties";

import { useUser } from "routes/dataroutes/UserData";

import { cx } from "utils";
import { trackApiKeyCopiedOnClick } from "utils/Tracking";


function useCopyApiKey() {
  const user = useUser();
  const [ copied, copyToClipboard ] = useCopyToClipboard();

  const copyApiKey = useCallback(() => {
    copyToClipboard(user?.apiKey);
    trackApiKeyCopiedOnClick('clicked');
  }, [ copyToClipboard, user?.apiKey]);

  return [ copied, copyApiKey ] as const;
}

export default function ApiKeyTextField(
  {
    className,
  }: {
    className?: string;
  }
) {
  const user = useUser();
  const [ copied, copyApiKey ] = useCopyApiKey();


  return (
    <DecoratedInputField
      type="text"
      readOnly
      value={ user?.apiKey }
      inputPaddingForTrailingElement="!pr-8"
      TrailingElement={
        <div className="flex items-center gap-x-1">
          <Transition show={ copied }>
            <div className={ cx(FAST_LINEAR_TRANSITION, "transition data-[closed]:opacity-0 data-[closed]:scale-0 origin-right") }>
              <Tag label="Copied" subtle />
            </div>
          </Transition>
          <RiFileCopyLine className="w-4 h-4" onClick={ copyApiKey }/>
        </div>
      }
      className={ cx("truncate", className) }
      onClick={ copyApiKey }
    />
  );
};

import { useFeatureSwitch } from "components/FeatureSwitch";


export default function useNewUpgradeDialog() {
  const useForBillingAddress = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
  const useForPaymentSources = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");

  return {
    useNewDialog: useForBillingAddress || useForPaymentSources,
    useForBillingAddress,
    useForPaymentSources
  };
};

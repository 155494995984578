import { useCallback, useMemo } from "react";
import { FetcherWithComponents } from "react-router-dom";
import _ from "lodash";

import { useFetcherData } from "v2/hooks/useFetcherData";

import { FetcherKeys } from "routes/dataroutes/Fetchers";

import Plan, { PlanPair } from "types/Plan";


type FetcherType = { plansPromise: Promise<PlanPair[]> };

export function usePlans(): {
  plansPromise: Promise<PlanPair[]> | undefined,
  planPairs: PlanPair[] | undefined,
  allPlans: Plan[] | undefined,
  fetcher: FetcherWithComponents<FetcherType>,
}
{
  const dataPromiseAccessor = useCallback((data: FetcherType) => data.plansPromise, []);

  const { promise, value, fetcher } = useFetcherData<PlanPair[], FetcherType>({
    fetcherKey: FetcherKeys.PLANS,
    fetcherHref: "/billing-data/plans",
    dataPromiseAccessor: dataPromiseAccessor
  });

  // TODO annual hack should come here. or rather should be implemented in the backend / billing service!!
  //  in Chargebee, annual prices are set -10% below the matching monthly price (x12), so discounts can be given without applying coupons
  //  here in the frontend we _DO_ use the correct price (monthly x 12) and silently apply a -10% annual "coupon", so the correct prices
  //  will be displayed everywhere
  //  unfortunately this hack has only been applied in the <PlanCardsList> component, all the other places like upgrade dialogs
  //  will use an incorrect calculation :(

  const allPlans = useMemo(() => {
    return _.compact(value?.flatMap(pp => [ pp.monthly, pp.annual ]));
  }, [ value ]);

  return {
    plansPromise: promise,
    planPairs: value,
    allPlans: allPlans,
    fetcher: fetcher!
  };
}

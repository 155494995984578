import {
  ChangeEvent,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import {
  Await,
  Form as ReactForm,
  useActionData,
  useFetcher,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useRevalidator,
  useRouteError
} from "react-router-dom";
import { format } from "date-fns";

import { ReactComponent as XIcon } from "assets/icons/x-icon.svg";

import scraperApi from "api";
import { fmtCurrency, fmtDate, fmtNumber } from "utils";
import getDataFromSuccessfulResponse from "utils/getDataFromSuccessfulResponse";
import { getCouponDiscountText, isAnnualPlan, manualRenewToThisIfScheduled } from "utils/planUtils";

import CouponCodeInput from "v2/components/billing/CouponCodeInput";
import ExtLink from "components/ExtLink";
import { useFeatureSwitch } from "components/FeatureSwitch";
import Button from "components/Button";
import Spinner from "components/Spinner";
import SubmitButton from "components/SubmitButton";
import TaggedText from "components/TaggedText";
import Toaster, { ToasterId } from "components/Toaster";

import OldUpgradeSubscriptionModal from "v2/components/billing/modals/OldUpgradeSubscriptionModal";
import UpgradeSubscriptionModal from "v2/components/billing/modals/UpgradeSubscriptionModal";
import {
  CheckingUnpaidInvoicesModalContent,
  DisabledBecauseOfUnpaidInvoicesModalContent
} from "v2/components/billing/modals/CancelSubscription";
import { ErrorMessage } from "components/Modal/ErrorMessage";
import { IOnboardingQuestion } from "components/onboarding/OnboardingPage/Pages";
import FormWithValidation from "components/FormWithValidation";
import QuestionsPage from "components/onboarding/OnboardingPage/QuestionsPage";

import { useActiveCoupons } from "v2/hooks/billing/useActiveCoupons";
import { usePlans } from "v2/hooks/billing/usePlans";
import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";
import { useDiscountPrice } from "v2/hooks/billing/useDiscountPrice";
import useFormattedActionError from "hooks/useFormattedActionError";
import useNewUpgradeDialog from "v2/hooks/billing/useNewUpgradeDialog";
import { useToasterFromActionData } from "hooks/useToasterFromActionData";

import Messages from "misc/Messages";

import { useChargebeePortals } from "providers/ChargebeePortalProvider";
import { ChargebeePortalSection } from "providers/ChargebeeProvider";

import { BillingActions, BillingInputName } from "v2/dataroutes/BillingData";
import IRouterActionError from "routes/dataroutes/IRouterActionError";
import { useUser } from "routes/dataroutes/UserData";

import { Coupon, CouponError } from "types/Coupons";

// Re-export
export { HostedScraperErrorReportModal, HostedScraperCancelJobModal, HostedScraperJobInfoModal } from 'components/Modal/HostedScraperModals';


type ModalChildren = ReactNode | ((renderProps: { closeModal: () => void }) => ReactNode);

interface IModalProps {
  headline: string;
  children: ModalChildren;
  closable?: boolean;
  closeOnEscapeKey?: boolean;
  onClose?: () => void;
  preventClosingIf?: boolean;
  goBackInHistoryOnClose?: boolean;
  goToOnClose?: string;
}

export default function Modal(
  {
    headline,
    children,
    closable = true,
    closeOnEscapeKey = true,
    ...closeOpts
  }: IModalProps
) {
  const navigate = useNavigate();
  const location = useLocation();

  const closeModal = useCallback(() => {
    if (closeOpts?.preventClosingIf) {
      return;
    }

    closeOpts?.onClose?.();

    if (closeOpts?.goBackInHistoryOnClose) {
      navigate(-1);
    } else {
      const prevPath = closeOpts?.goToOnClose || location?.state?.backgroundLocation || "/";
      navigate(prevPath, { viewTransition: true });
    }
  }, [ closeOpts, navigate, location ]);

  const closeOnEsc = useCallback((e: KeyboardEvent) => {
    if ((e.key === "Escape") && closeOnEscapeKey) {
      closeModal();
    }
  }, [ closeModal, closeOnEscapeKey ]);

  useEffect(() => {
    if (closeOnEscapeKey) {
      document.addEventListener("keydown", closeOnEsc);
      return () => document.removeEventListener("keydown", closeOnEsc);
    }
  }, [ closeOnEscapeKey, closeOnEsc ]);

  // workaround for the 'There are no focusable elements inside the <FocusTrap />' error
  let focusRef = useRef(null);

  // TODO might need to customize the viewTransition animation here
  //  by default it's a simple cross-fade effect, but originally modals were also using a bit of y-translate
  return (
    // have to "disable" the onClose callback, so dialogs won't close on outside clicks
    <Dialog as="div" className="relative z-30" open onClose={ () => {} } initialFocus={focusRef}>
      <div className="fixed inset-0 transition-opacity bg-gray-500 dark:bg-neutral-500 bg-opacity-75 pointer-events-none backdrop-blur-sm" />

      <div className="fixed inset-0 z-30 overflow-y-auto" ref={focusRef} >
        <div className="flex items-end justify-center min-h-full p-4 sm:items-center sm:p-0">
          <DialogPanel className="relative w-full overflow-hidden transition-all transform bg-white sm:min-w-[500px] sm:max-w-min">
            <div>
              <div className="flex items-center justify-between px-5 py-4 border-b border-b-borderColor dark:border-b-neutral-200">
                <DialogTitle
                  as="span"
                  className="text-lg leading-6 text-brandDarkest dark:text-primary-800"
                >
                  {headline}
                </DialogTitle>

                { closable && (
                  <XIcon
                    className="w-8 h-8 p-1.5 transition-colors rounded-sm cursor-pointer text-gray dark:text-neutral-600 hover:text-gray-800 dark:hover:text-neutral-800 hover:bg-slate-100"
                    onClick={ closeModal }
                  />
                )}
              </div>
              { typeof children === "function" ? children({ closeModal }) : children }
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export function ModalContent({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex flex-col p-5 gap-y-12">
      {children}
    </div>
  );
}

export function ModalSections({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex flex-col gap-y-4 text-gray dark:text-neutral-600">
      {children}
    </div>
  );
}

export function ModalButtonsSection({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex justify-end gap-x-4 items-center">
      {children}
    </div>
  );
}

export function CancelSubscriptionChangeModal() {
  const navigation = useNavigation();

  return (
    <Modal headline="Cancel Subscription Change" preventClosingIf={ navigation.state !== "idle" }>
      { ({ closeModal }) =>
        <ReactForm method="POST" action="/v2/billing">
          <ModalContent>
            <ModalSections>
              <div>Are you sure you want to cancel your subscription change?</div>
              <div>Your scheduled changes will be canceled</div>
            </ModalSections>
            <ModalButtonsSection>
              <Button
                text="Keep Change"
                className="button button-secondary"
                size="MD"
                onClick={ closeModal }
              />
              <SubmitButton
                text="Cancel Change"
                className="button button-primary destructive"
                size="MD"
                { ...BillingActions.remove_scheduled_changes }
              />
            </ModalButtonsSection>
          </ModalContent>
        </ReactForm>
      }
    </Modal>
  );
}

export function ConfirmRenewalModal() {
  const { planId } = useParams() as { planId: string };

  const user = useUser();

  const navigation = useNavigation();

  const { allPlans } = usePlans();
  const currentPlan = allPlans?.find(p => p.planSlug === planId);

  const { activeSubscription } = useActiveSubscription();
  const couponsFetcher = useFetcher<Coupon | CouponError | undefined>();
  const discountPrice = useDiscountPrice({
      useScheduledSubscriptionIfAny: manualRenewToThisIfScheduled(activeSubscription?.scheduledSubscription?.planSlug),
      coupon: getDataFromSuccessfulResponse<Coupon>(couponsFetcher.data)
  });
  const isInvoiceNeeded = !activeSubscription || (activeSubscription.nextRenewalAt === activeSubscription.currentTermEnd);

  if (user?.isRenewalAllowed) {
    return (
      <Modal headline="Confirm Renew Subscription" preventClosingIf={ navigation.state !== "idle" }>
        { ({ closeModal }) =>
          <>
            <input type="hidden" name={ BillingInputName.chargebeeSubscriptionId } value={ activeSubscription?.chargebeeSubscriptionId }/>
            <input type="hidden" name={ BillingInputName.keepScheduledChanges } value={ isAnnualPlan(activeSubscription?.planSlug) ? "true" : "false" }/>
            <ReactForm method="POST" action="/v2/billing">
              <ModalContent>
                <ModalSections>
                  <div>Are you sure you want to renew your subscription?</div>
                  <div>
                    By renewing your subscription, we will reset your credit counters
                    { isInvoiceNeeded && (<>, Invoice <span className="font-bold">{ fmtCurrency(discountPrice / 100) }</span> now</>) }
                    { (isAnnualPlan(planId) && !isInvoiceNeeded) ? " and shorten your annual subscription by one month." : " and set your billing date to today." }
                  </div>
                  {/* TODO it is hardcoded here that we don't allow coupons for annual subscriptions. should we change it and let Chargebee make that decision? */ }
                  { (user?.canUseAllCoupons || (user?.canUseCoupons && !isAnnualPlan(currentPlan?.planSlug))) && (
                    <CouponCodeInput
                      targetPlanSlug={ planId }
                      fetcher={ couponsFetcher }
                    />
                  ) }
                </ModalSections>
                <ModalButtonsSection>
                  <Button
                    text="Cancel"
                    className="button button-secondary"
                    size="MD"
                    onClick={ closeModal }
                  />
                  <SubmitButton
                    text="Renew Subscription"
                    className="button button-primary"
                    size="MD"
                    checkFormValidity
                    { ...BillingActions.renew }
                  />
                </ModalButtonsSection>
              </ModalContent>
            </ReactForm>
          </>
        }
      </Modal>
    );
  } else {
    return (
      <Modal headline="Confirm Renew Subscription" preventClosingIf={ navigation.state !== "idle" }>
        { ({ closeModal }) =>
          <ModalContent>
            <ModalSections>
              <TaggedText message={Messages.renewalDisabledTaggedMessage} />
            </ModalSections>
            <ModalButtonsSection>
              <Button text="Ok" className="button button-primary" onClick={ closeModal } size="MD" />
            </ModalButtonsSection>
          </ModalContent>
        }
      </Modal>
    );
  }
}

export function UpgradeModal() {
  const { useNewDialog } = useNewUpgradeDialog();

  return useNewDialog ?
    <UpgradeSubscriptionModal /> :
    <OldUpgradeSubscriptionModal />;
}

export function DowngradeModal() {
  let { planId } = useParams() as { planId: string };

  const { allPlans } = usePlans();

  const targetPlan = allPlans?.find(p => p.planSlug === planId);
  const price = useDiscountPrice({ targetPlan }); // not checking scheduled changes here, manually downgrading to a plan should simply drop all current scheduled changes

  const navigation = useNavigation();

  return (
    <Modal headline="Downgrade Subscription" preventClosingIf={ navigation.state !== "idle" }>
      { ({ closeModal }) =>
        <ReactForm method="POST" action="/v2/billing">
          <input type="hidden" { ...BillingActions.downgrade } />
          <input type="hidden" name={ BillingInputName.targetPlanId } value={ planId } />
          <ModalContent>
            <ModalSections>
              <div>
                Are you sure you want to downgrade your subscription to{" "}
                <span className="font-bold capitalize">
                {targetPlan?.planName}
              </span>
                ?
              </div>
              <div>
                By downgrading your subscription, we will reset your credit
                counters, Invoice{" "}
                <span className="font-bold">
                {fmtCurrency(price / 100)}{" "}
              </span>{" "}
                and update your billing date either immediately or at the end of
                current cycle.
              </div>
            </ModalSections>
            <div className="flex flex-col items-end gap-y-2 gap-x-1">
              <SubmitButton
                text="Downgrade at End of Cycle"
                className="button button-primary destructive"
                size="SM"
                name={ BillingInputName.immediate }
                value="false"
                showSubmitSpinnerIf={ () => navigation.formData?.get(BillingInputName.immediate) === "false" }
              />
              <div className="flex justify-between w-full">
                <Button
                  text="Cancel"
                  className="button button-secondary"
                  size="SM"
                  onClick={ closeModal }
                />
                <SubmitButton
                  text="Downgrade Immediately"
                  className="button button-primary destructive"
                  size="SM"
                  name={ BillingInputName.immediate }
                  value="true"
                  showSubmitSpinnerIf={ () => navigation.formData?.get(BillingInputName.immediate) === "true" }
                />
              </div>
            </div>
          </ModalContent>
        </ReactForm>
      }
    </Modal>
  );
}

export function AutoRenewalModal() {
  const { percentage } = useParams() as { percentage: string };
  const navigation = useNavigation();
  const location = useLocation();
  const user = useUser();
  const actionData = useActionData() as IRouterActionError | undefined;


  if (user?.isRenewalAllowed) {
    return (
      <Modal headline="Automatic renewals" preventClosingIf={ navigation.state !== "idle" }>
        { ({ closeModal }) =>
          <ReactForm method="POST" replace state={ location.state }>
            <input type="hidden" name={ BillingInputName.autoRenewalSetting } value={ percentage } />
            <ModalContent>
              <ModalSections>
                <div>Are you sure you want to auto renew your subscription?</div>
                <div>
                  <p>
                    Your subscription will be renewed automatically after you have
                    used <span className="font-bold">{ percentage }%</span> of your
                    credits.
                  </p>
                </div>
                <ErrorMessage errorMessage={ actionData?.error?.message } closeModalCallback={ closeModal } />
              </ModalSections>
              <ModalButtonsSection>
                <Button
                  text="Cancel"
                  className="button button-secondary"
                  size="MD"
                  onClick={ closeModal }
                />
                <SubmitButton
                  text={ `Auto Renew at ${percentage}%`}
                  className="button button-primary"
                  size="MD"
                  disabled={ actionData?.error !== undefined }
                />
              </ModalButtonsSection>
            </ModalContent>
          </ReactForm>
        }
      </Modal>
    );
  } else {
    return (
      <Modal headline="Automatic renewals" preventClosingIf={ navigation.state !== "idle" }>
        { ({ closeModal }) =>
          <ModalContent>
            <ModalSections>
              <TaggedText message={Messages.renewalDisabledTaggedMessage} />
            </ModalSections>
            <ModalButtonsSection>
              <Button
                text="Ok"
                className="button button-primary"
                size="MD"
                onClick={ closeModal }
              />
            </ModalButtonsSection>
          </ModalContent>
        }
      </Modal>
    );
  }
}

export function ClearAutoRenewalModal() {
  const navigation = useNavigation();
  const location = useLocation();
  const { activeSubscription } = useActiveSubscription();
  const actionData = useActionData() as IRouterActionError | undefined;

  let nextRenewalAt: string | undefined;

  if (activeSubscription && activeSubscription.nextRenewalAt) {
    nextRenewalAt = `(next renewal ${format(activeSubscription.nextRenewalAt * 1000, 'EEE, MMMM do yyyy')})`;
  }


  return (
    <Modal headline="Standard renewal cycle" preventClosingIf={ navigation.state !== "idle" }>
      { ({ closeModal }) =>
        <ReactForm method="POST" replace state={ location.state }>
          <input type="hidden" name={ BillingInputName.autoRenewalSetting } value="clear" />
          <ModalContent>
            <ModalSections>
              <p>
                Are you sure you want to set your renewal to the default renewal settings?
              </p>
              <p>
                Your subscription will be renewed by the end of your billing cycle{nextRenewalAt ? ` ${nextRenewalAt}, ` : ', '}
                even if you run out of all your credits before the end of the billing cycle.
              </p>
              <p>You can learn more about our renewal feature and other options in our {' '}
                <ExtLink className="underline" to="https://docs.scraperapi.com/faq/plans-and-billing/what-happens-if-i-run-out-of-credits-before-the-end-of-my-current-subscription">documentation</ExtLink>.
              </p>
              <ErrorMessage errorMessage={ actionData?.error.message } closeModalCallback={ closeModal } />
            </ModalSections>
            <ModalButtonsSection>
              <Button
                text="Cancel"
                className="button button-secondary"
                size="MD"
                onClick={ closeModal }
              />
              <SubmitButton
                text="OK"
                className="button button-primary"
                size="MD"
                disabled={ actionData?.error !== undefined }
              />
            </ModalButtonsSection>
          </ModalContent>
        </ReactForm>
      }
    </Modal>
  );
}

export function CouponsModal() {
  const { activeCoupons } = useActiveCoupons();
  const navigation = useNavigation();


  return (
    <Modal headline="Manage Coupons" preventClosingIf={ navigation.state !== "idle" }>
      { ({ closeModal }) =>
        <ReactForm method="POST" action="/v2/billing">
          <div className="flex flex-col p-5 space-y-4">
            <div className="flex flex-col space-y-6 text-gray dark:text-neutral-600">
              { activeCoupons?.map((coupon, index) => {
                return (
                  <div
                    key={`${coupon.coupon_code}-${coupon.coupon_type}-${index}`}
                  >
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <div className="font-bold">{coupon.coupon_code}</div>
                        <div className="text-sm">
                          Active since{" "}
                          {coupon.added_at && fmtDate(new Date(coupon.added_at))}
                        </div>
                      </div>
                      <div className="">{ getCouponDiscountText(coupon) }</div>
                    </div>
                  </div>
                );
              })}
              { activeCoupons?.length && (
                <hr className="text-borderColor dark:text-neutral-200" />
              )}
            </div>
            <CouponCodeInput
              className="min-h-[98px] space-y-2"
              required
              autoFocus
            />
            <ModalButtonsSection>
              <Button
                text="Dismiss"
                className="button button-secondary"
                size="MD"
                onClick={ closeModal }
              />
              <SubmitButton
                text="Add coupon"
                className="button button-primary"
                size="MD"
                checkFormValidity
                { ...BillingActions.add_coupon }
              />
            </ModalButtonsSection>
          </div>
        </ReactForm>
      }
    </Modal>
  );
}

export function DeleteAccountModal() {
  useToasterFromActionData();

  const navigate = useNavigate();
  const navigation = useNavigation();
  const actionData = useActionData() as { response: string } | undefined;

  useEffect(() => {
    if (actionData?.response) {
      if (actionData.response === "deleted_permanently") {
        setTimeout(async () => {
          await scraperApi.auth.logout();
          navigate("/login");
        }, 6000);
      }

      navigate("/v2/billing");
    }
  }, [ actionData, navigate ]);


  return (
    <Modal headline="Request Account Deletion" preventClosingIf={ navigation.state !== "idle" }>
      { ({ closeModal }) =>
        <ReactForm method="POST">
          <ModalContent>
            <ModalSections>
              <div>Are you sure you want to delete your account?</div>
              <div>
                By deleting your account you won't be able to login anymore
                and your API key will be invalid.
              </div>
            </ModalSections>
            <ModalButtonsSection>
              <Button
                text="Keep Account"
                className="button button-secondary"
                size="MD"
                onClick={ closeModal }
              />
              <SubmitButton
                text="Request account deletion"
                className="button button-primary destructive"
                size="MD"
              />
            </ModalButtonsSection>
          </ModalContent>
        </ReactForm>
      }
    </Modal>
  );
}

export function BillingInfoErrorModal({ section }: { section: ChargebeePortalSection }) {
  const billingError = (useRouteError() as any).data;

  const { openChargebeePortal } = useChargebeePortals();
  const revalidator = useRevalidator();

  return (
    <Modal headline={ billingError.title }>
      { ({ closeModal }) =>
        <ModalContent>
          <ModalSections>
            <div>{ billingError.message }</div>
          </ModalSections>
          <ModalButtonsSection>
            <Button
              text="Cancel"
              className="button button-secondary"
              size="MD"
              onClick={ closeModal }
            />
            <Button
              text={ billingError.buttonText }
              className="button button-primary"
              size="MD"
              onClick={ () => openChargebeePortal(section, { close: revalidator.revalidate }) }
              icon={ revalidator.state === "idle" ? undefined : { Icon: Spinner } }
              disabled={ revalidator.state !== "idle" }
            />
          </ModalButtonsSection>
        </ModalContent>
      }
    </Modal>
  );
}


export namespace CancelSubscription {

  function CancelSubscriptionModal(modalProps: Omit<IModalProps, "headline">) {
    return (
      <Modal { ...modalProps } headline="Cancel Subscription" />
    );
  }

  export function CancelModal() {
    const showCancellationSurvey = useFeatureSwitch("REACT_APP_CANCELLATION_SURVEY_USERS");
    const user = useUser();
    const notPaidInvoices = useLoaderData() as { notPaidInvoicesPromise: Promise<any> };
    const actionData = useActionData();
    const navigation = useNavigation();
    const errorMessage = useFormattedActionError(actionData);

    return (
      <CancelSubscriptionModal preventClosingIf={ navigation.state !== "idle" }>
        { ({ closeModal }) =>
          <Suspense fallback={ <CheckingUnpaidInvoicesModalContent /> }>
            <Await
              errorElement={ <DisabledBecauseOfUnpaidInvoicesModalContent closeModalCallback={ closeModal } /> }
              resolve={ notPaidInvoices.notPaidInvoicesPromise }
            >
              <ReactForm method="POST">
                { showCancellationSurvey && <input type="hidden" name={ BillingInputName.showCancellationSurvey } value={ 1 } /> }
                <ModalContent>
                  <ModalSections>
                    <div>Are you sure you want to cancel your subscription?</div>
                    <div>
                      Your subscription will remain with your current plan until the end
                      of the billing period.{ " " }
                      { user?.isBannedFromFree && (
                        <>After that, your subscription will be cancelled and your API key will be removed.</>
                      ) }
                      { !user?.isBannedFromFree && (
                        <>After that, you will be downgraded to the{ " " }
                          <span className="font-bold">FREE</span> plan, with{ " " }
                          <span className="font-bold">{ fmtNumber(1000) } API credits per month</span>.
                        </>
                      ) }
                    </div>
                    <ErrorMessage errorMessage={ errorMessage } closeModalCallback={ closeModal } />
                  </ModalSections>
                  <ModalButtonsSection>
                    <Button
                      text="Keep Subscription"
                      className="button button-secondary"
                      size="MD"
                      onClick={ closeModal }
                    />
                    <SubmitButton
                      text="Cancel Subscription"
                      className="button button-primary"
                      size="MD"
                      { ...BillingActions.cancel }
                      disabled={ (actionData as IRouterActionError)?.error?.meta?.shouldNotRetry }
                    />
                  </ModalButtonsSection>
                </ModalContent>
              </ReactForm>
            </Await>
          </Suspense>
        }
      </CancelSubscriptionModal>
    );
  }

  const cancellationSurveySecondaryQuestions: { [index: string]: string; } = {
    "Lower success rate than expected": "What was the success rate you experienced and/or for what domain?",
    "Price was too high": "Do you have any other feedback?",
    "Dissatisfied with support": "Do you have any other feedback what to improve on our side?",
    "Service was difficult to use": "What would have helped you?",
    "Experienced slow response times": "Can you tell us the domain(s) you experienced slow response time?",
    "Found alternative": "Can you tell us why you moved to another solution and which alternative you chose?",
    "Documentation was unclear": "What would have helped you?",
    "Specific feature was missing": "Can you tell us which feature you were looking for?",
    "Existing feature not working as expected": "Which feature were you not satisfied with and why?",
    "Experienced frequent downtimes": "What was the success rate you experienced and/or for what domain?",
    "Other/None of the above": "Do you have any other feedback?"
  };

  const cancellationSurveyFirstQuestion: IOnboardingQuestion = {
    id: "cancellation-reason",
    question: "Can you let us know why you decided to cancel?",
    type: "single-select",
    mandatory: true,
    answers: Object.keys(cancellationSurveySecondaryQuestions)
  };

  export function CancellationSurveyModal() {
    const { activeSubscription } = useActiveSubscription();
    const [ questions, setQuestions ] = useState([ cancellationSurveyFirstQuestion ]);

    const onAnswerCallback = useCallback((event: ChangeEvent<HTMLFormElement>) => {
      if ((event.target.type === "radio") && event.target.value) {
        setQuestions([
          cancellationSurveyFirstQuestion,
          {
            id: "cancellation-feedback",
            question: cancellationSurveySecondaryQuestions[event.target.value],
            type: "text-input",
            mandatory: false,
            answers: [ "" ]
          }
        ])
      }
    }, []);

    const actionData = useActionData() as string | IRouterActionError | undefined;
    const [ answersSavedToastId, setAnswersSavedToastId ] = useState<ToasterId | undefined>();
    const navigate = useNavigate();

    useEffect(() => {
      if (actionData === "OK") {
        if (!answersSavedToastId) {
          setAnswersSavedToastId(
            Toaster.success("Subscription cancelled and answers sent!", "We are sorry to see you go. Thank you for participating in our survey.")
          );
          navigate("/v2/billing");
        }
      }
    }, [ actionData, navigate, answersSavedToastId, setAnswersSavedToastId ]);


    return (
      <CancelSubscriptionModal goToOnClose="/v2/billing">
        <ModalContent>
          <div className="flex flex-col text-gray dark:text-neutral-600">
            <div>
              <p>We're sorry to see you go!</p>
              <p>Your subscription was cancelled and will end on { fmtDate(new Date((activeSubscription?.currentTermEnd || 0) * 1000)) }.</p>
            </div>

            <FormWithValidation
              method="POST"
              onChange={ onAnswerCallback }
            >
              <QuestionsPage page={{ questions: questions }} questionsClassName="font-semibold" showQuestionsHint={ false } />

              <div className="flex flex-row justify-end pt-8">
                <SubmitButton
                  text="send"
                  checkFormValidity
                />
              </div>
            </FormWithValidation>

          </div>
        </ModalContent>
      </CancelSubscriptionModal>
    );
  }

  export function KeepSubscriptionModal() {
    const navigation = useNavigation();
    const { activeSubscription } = useActiveSubscription();

    return (
      <Modal headline="Reactivate Subscription" preventClosingIf={ navigation.state !== "idle" }>
        { ({ closeModal }) =>
          <ModalContent>
            <ModalSections>
              <div>Your subscription was cancelled and will end on {fmtDate(new Date((activeSubscription?.currentTermEnd || 0) * 1000))}.</div>
              <TaggedText message="If you have any follow up questions or want to subscribe to a custom plan, please [contact our support team|contact_support]."
                          tagCallbacks={{ contact_support: closeModal }}
              />
              <div>You cancelled your subscription by accident? Just click on the "Reactivate subscription" button below and your subscription will be renewed with the next cycle.</div>
            </ModalSections>
            <ReactForm method="POST" action="/v2/billing">
              <ModalButtonsSection>
                <SubmitButton
                  text="Reactivate subscription"
                  size="MD"
                  className="button button-secondary"
                  { ...BillingActions.remove_scheduled_changes }
                />
                <Button
                  text="Ok"
                  size="MD"
                  onClick={ closeModal }
                />
              </ModalButtonsSection>
            </ReactForm>
          </ModalContent>
        }
      </Modal>
    );
  }

}

import { useMemo } from "react";
import { useFetcherData } from "v2/hooks/useFetcherData";
import { checkValidity } from "utils/validationUtils";


export default function useBillingInfo() {
  // TODO use proper types here
  const { value } = useFetcherData<any, any>({ fetcherHref: "/billing-data/billing-info" });

  const isValidBillingAddress = useMemo(() => {
    const billingAddress = value?.billingAddress;

    if (!billingAddress) {
      return undefined;
    }

    if (Object.keys(billingAddress).length === 0) {
      // empty billing address
      return false;
    }

    for (const field in billingAddress) {
      if (!checkValidity(billingAddress[field].value, billingAddress[field].validation)) {
        return false;
      }
    }

    return true;
  }, [ value?.billingAddress ]);

  const hasValidPaymentSource = useMemo(() => {
    const paymentSources = value?.paymentSources;

    if (paymentSources === undefined) {
      return undefined;
    } else {
      return paymentSources.find((paymentSource: any) => paymentSource.payment_source.status === "valid") !== undefined;
    }
  }, [ value?.paymentSources ]);

  return {
    billingAddress: {
      data: value?.billingAddress,
      isValid: isValidBillingAddress,
    },
    paymentSources: {
      data: value?.paymentSources,
      hasValidPaymentSource
    }
  };
};

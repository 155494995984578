import { useCallback, useMemo } from "react";

import Select, { SelectOption } from "components/Select";

import { SupportedLanguages } from "routes/dashboard/ApiPlaygroundTypes";

import { useQuickStartContext } from "./QuickStartCard";
import { FIXED_WIDTH } from "./constants";
import { trackWelcomeDashboardProgrammingLanguageChange } from "utils/Tracking";


const languages: SelectOption<SupportedLanguages>[] = [
  {
    name: "cURL",
    value: "curl"
  },
  {
    name: "Python",
    value: "python"
  },
  {
    name: "NodeJS",
    value: "nodejs"
  },
  {
    name: "PHP",
    value: "php"
  },
  {
    name: "Ruby",
    value: "ruby"
  },
  {
    name: "Java",
    value: "java"
  }
];


export default function LanguageSelect() {
  const { programmingLanguage, setProgrammingLanguage } = useQuickStartContext();

  const selectedLanguage = useMemo(() => {
    return languages.find(lang => lang.value === programmingLanguage) || languages[1];
  }, [ programmingLanguage ]);

  const changeLanguage = useCallback((language: SelectOption<SupportedLanguages>) => {
    setProgrammingLanguage(language.value);
    trackWelcomeDashboardProgrammingLanguageChange(language.value);
  }, [ setProgrammingLanguage ]);


  return (
    <Select
      value={ selectedLanguage }
      onChange={ changeLanguage }
      options={ languages }
      size="SM"
      className={ FIXED_WIDTH }
    />
  );
};

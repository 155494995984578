import { ReactNode, useState } from "react";
import { Transition, TransitionChild } from "@headlessui/react";
import { createTheme } from "thememirror";
import { tags } from "@lezer/highlight";

import { RiZoomInLine, RiZoomOutLine } from "@remixicon/react";

import * as colors from "tailwind.config/colors";

import Button from "components/Button";
import { CodeView, CodeViewLanguages } from "components/CodeView";

import { useCopyToClipboard } from "hooks/useCopyToClipboard";

import { DEFAULT_TRANSITION_FUNC_AND_DURATION, FAST_LINEAR_TRANSITION } from "misc/transitionProperties";

import { cx } from "utils";
import { trackWelcomeDashboardCopySnippet } from "utils/Tracking";


const codeViewTheme = createTheme(
  {
    variant: "dark",
    settings: {
      background: colors.primary["900"],
      foreground: colors.primary["100"],
      caret: "",
      selection: "",
      lineHighlight: "",
      gutterBackground: colors.primary["900"],
      gutterForeground: colors.neutral["600"]
    },
    styles: [
      {
        tag: tags.keyword,
        color: colors.warning["400"]
      },
      {
        tag: tags.string,
        color: colors.accent["400"]
      },
      {
        tag: tags.propertyName,
        color: colors.success["400"]
      },
      {
        tag: tags.comment,
        color: colors.primary["300"]
      }
    ]
  }
);


function ButtonBar(
  {
    canCopy,
    copyContent,
    copiedMessage,
    isZoomed,
    setZoomCallback
  }: {
    canCopy: boolean;
    copyContent?: string;
    copiedMessage?: string;
    isZoomed?: boolean;
    setZoomCallback?: (value: boolean) => void;
  }
) {
  const [ recentlyCopied, copyToClipboard ] = useCopyToClipboard();

  const copyToClipboardOnClick = () => {
    copyToClipboard(copyContent);
    trackWelcomeDashboardCopySnippet('copied');
  }


  if (canCopy || (isZoomed !== undefined)) {
    return (
      <div className="absolute top-3 right-4 flex flex-row gap-x-2">
        { (isZoomed !== undefined) && (
          <Button
            size="SM"
            className="button button-accent transition-opacity duration-200 opacity-65 group-hover/code-view-area:opacity-100"
            icon={ { Icon: isZoomed ? RiZoomOutLine : RiZoomInLine } }
            onClick={ () => setZoomCallback?.(!isZoomed) }
          />
        ) }

        { canCopy && (
          <div className="relative">
            <Button
              text="Copy"
              size="SM"
              className="button button-accent transition-opacity duration-200 opacity-65 group-hover/code-view-area:opacity-100"
              onClick={ () => copyToClipboardOnClick() }
            />
            <div className="absolute -top-4 left-[50%]">
              <Transition show={ recentlyCopied }>
                {/* TODO should use a <Tag> here instead of a plain div */}
                <div className={ cx(
                  "relative -left-[50%] bg-accent-300 rounded px-1 py-0.5 text-xs text-nowrap z-10",
                  FAST_LINEAR_TRANSITION,
                  "transition origin-bottom",
                  "data-[closed]:opacity-0 data-[closed]:scale-0"
                ) }
               >
                  { copiedMessage }
                </div>
              </Transition>
            </div>
          </div>
        ) }
      </div>
    );
  } else {
    return <></>;
  }
}

function CodeViewContent(
  {
    codeExample,
    language,
    fullScreen = false,
  }: {
    codeExample: string | undefined;
    language: CodeViewLanguages;
    fullScreen?: boolean;
  }
) {
  return (
    <CodeView
      content={ codeExample }
      language={ language }
      editable={ false }
      className="text-sm"
      width="max-content"
      minHeight={ fullScreen ? "100%" : undefined }
      basicSetup={ {
        highlightActiveLine: false,
        highlightActiveLineGutter: false,
      } }
      theme={ codeViewTheme }
    />
  );
}

function CodeViewArea(
  {
    className,
    children,
  }: {
    className?: string;
    children: ReactNode;
  }
) {
  return (
    <div className={ cx(
      "bg-primary-900 pt-4 pl-4 pr-1 dark:rounded overflow-auto codeview-scrollbar flex-grow group/code-view-area",
      className
    ) }>
      { children }
    </div>
  );
}

export function QuickStartCodeView(
  {
    language,
    codeExample,
    height = "180px",
    maxHeight,
    canCopy = true,
    copiedMessage = "Code example copied to clipboard",
    canZoom
  }: {
    language: CodeViewLanguages;
    codeExample: string | undefined;
    height?: number | string;
    maxHeight?: number | string;
    canCopy?: boolean;
    copiedMessage?: string;
    canZoom?: boolean;
  }
) {
  const [ showFullScreen, setShowFullScreen ] = useState(false);

  return (
    <div className="flex relative grow" style={ { height: height, maxHeight: maxHeight } }>
      <CodeViewArea className="!pt-12">
        <CodeViewContent codeExample={ codeExample } language={ language } />
        <ButtonBar
          canCopy={ canCopy }
          copiedMessage={ copiedMessage }
          copyContent={ codeExample }
          isZoomed={ canZoom ? showFullScreen : undefined }
          setZoomCallback={ canZoom ? setShowFullScreen : undefined }
        />
      </CodeViewArea>

      <Transition show={ showFullScreen }>
        <TransitionChild>
          {/* fullscreen backdrop tracking outside clicks */}
          <div
            className={ cx(
              "fixed inset-0 size-full flex items-center justify-center z-20",
              "bg-neutral-500 bg-opacity-75 backdrop-blur-sm",
              "transition-opacity",
              DEFAULT_TRANSITION_FUNC_AND_DURATION,
              "data-[closed]:opacity-0"
            ) }
            onClick={ () => setShowFullScreen(false) }
          >
            <TransitionChild>
              <div
                onClick={ (event) => event.stopPropagation() }
                className={ cx(
                  "relative flex min-w-[75%] max-w-[90%] min-h-[50%] max-h-[90%]",
                  "transition",
                  DEFAULT_TRANSITION_FUNC_AND_DURATION,
                  "data-[closed]:opacity-0",
                  "data-[closed]:translate-y-4 data-[closed]:scale-95"
                ) }
              >
                <CodeViewArea className="!pt-12">
                  <CodeViewContent codeExample={ codeExample } language={ language } fullScreen />
                  <ButtonBar
                    canCopy={ canCopy }
                    copyContent={ codeExample }
                    copiedMessage={ copiedMessage }
                    isZoomed={ canZoom ? showFullScreen : undefined }
                    setZoomCallback={ canZoom ? setShowFullScreen : undefined }
                  />
                </CodeViewArea>
              </div>
            </TransitionChild>
          </div>
        </TransitionChild>
      </Transition>
    </div>
  );
}

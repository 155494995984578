import { useCallback } from "react";
import { FetcherWithComponents, useActionData } from "react-router-dom";

import { useFetcherData } from "v2/hooks/useFetcherData";

import { FetcherKeys } from "routes/dataroutes/Fetchers";
import IRouterActionError, { ActionError } from "routes/dataroutes/IRouterActionError";

import ActiveSubscription from "types/ActiveSubscription";


export const ACTIVE_SUBSCRIPTION_ROUTE_ID = "active-subscription-route";

export type ActiveSubscriptionLoaderDataType = { activeSubscriptionPromise: Promise<ActiveSubscription> };
export type ActiveSubscriptionActionDataType = { activeSubscription: ActiveSubscription };

export function useActiveSubscription(): {
  activeSubscriptionPromise: Promise<ActiveSubscription> | undefined;
  activeSubscription: ActiveSubscription | undefined;
  activeSubscriptionError: ActionError | undefined;
  activeSubscriptionFetcher: FetcherWithComponents<ActiveSubscriptionLoaderDataType> | undefined;
} {
  const actionData = useActionData() as ActiveSubscriptionActionDataType | IRouterActionError | undefined;
  const actionError = (actionData as IRouterActionError)?.error;
  const actionSubscription = (actionData as ActiveSubscriptionActionDataType)?.activeSubscription;

  const dataPromiseAccessor = useCallback((data: ActiveSubscriptionLoaderDataType) => data.activeSubscriptionPromise, []);

  const { promise, value, fetcher } = useFetcherData<ActiveSubscription, ActiveSubscriptionLoaderDataType>({
    fetcherKey: FetcherKeys.ACTIVE_SUBSCRIPTION,
    fetcherHref: "/v2/billing",
    dataPromiseAccessor: dataPromiseAccessor,
    routeLoaderId: ACTIVE_SUBSCRIPTION_ROUTE_ID
  });

  return {
    activeSubscriptionPromise: promise,
    activeSubscription: ((actionSubscription !== undefined) && (actionError === undefined)) ? actionSubscription : value,
    activeSubscriptionError: actionError,
    activeSubscriptionFetcher: fetcher
  };
}

import { useActionData } from "react-router-dom";

import Toaster, { ToasterProps } from "components/Toaster";


export interface IToasterActionData {
  toaster?: ToasterProps;
}

export function useToasterFromActionData() {
  const actionData = useActionData() as IToasterActionData | undefined;

  if (actionData?.toaster) {
    Toaster.showToast(actionData?.toaster);
  }
}

export function addToasterActionData<T>(actionData: T, toaster: ToasterProps): T & IToasterActionData {
  return { ...actionData, toaster };
}

import { MouseEvent, useMemo } from "react";
import { Link } from "react-router-dom";

import Card from "components/Card";
import ExtLink from "components/ExtLink";
import Icons, { IconName } from "components/Icons";
import PinButton from "components/PinButton";

import { RecentlyViewedPageType, useRecentlyViewed } from "providers/RecentlyViewed/RecentlyViewedProvider";

import { cx } from "utils";
import { trackRecentlyViewedOnClick, trackRecentlyViewedPinnedPageOnClick } from "utils/Tracking";


function refineTitle(title: string | undefined) {
  if (title?.startsWith("DataPipeline Projects /")) {
    return "DataPipeline: " + title.slice(23);
  }

  return title;
}

function getIconComponent(iconName?: IconName) {
  const defaultIcon = () => <></>;  // TODO should have a real default icon here
  if (iconName) {
    return Icons[iconName] || defaultIcon;
  } else {
    return defaultIcon;
  }
}

function getLinkComponent(location: string) {
  if (location?.startsWith("http")) {
    return ExtLink;
  } else {
    return Link;
  }
}

export default function RecentlyViewedCard() {
  const { recentlyViewedPages, changeRecentlyViewedPages } = useRecentlyViewed()!;
  const filteredRecentlyViewedPages = useMemo(() => {
    return recentlyViewedPages.filter(p => p.location);
  }, [ recentlyViewedPages ]);

  if (filteredRecentlyViewedPages.length === 0) {
    return <></>;
  } else {

    const pinButtonOnClick = (event: MouseEvent<HTMLDivElement>, page: RecentlyViewedPageType) => {
      changeRecentlyViewedPages({ action: page.pinned ? "unpin" : "pin", page: page });
      event.preventDefault();
      if(!page.pinned && page.title) trackRecentlyViewedPinnedPageOnClick(page.title)
    }
  
    return (
      <Card
        title="Recently viewed"
        size="MD"
        borderless
      >
        <div className={ cx("flex flex-row flex-nowrap overflow-x-auto gap-5 scroll-smooth snap-x snap-mandatory", filteredRecentlyViewedPages.length > 3 && "justify-between") }>
          { filteredRecentlyViewedPages.map((page, idx) => {
            const IconComponent = getIconComponent(page.iconName);
            const LinkComponent = getLinkComponent(page.location!);

            return (
              <LinkComponent to={ page.location! } key={ idx } onClick={() => trackRecentlyViewedOnClick('clicked')}>
                <Card
                  className="w-56 min-w-56 hover:bg-neutral-50 transition-colors duration-200 relative snap-always snap-start h-full"
                >
                  <div className="flex flex-row gap-x-4 items-center h-full">
                    <IconComponent className="size-11 min-h-11 min-w-11 p-1.5 border border-neutral-200 bg-neutral-50 text-neutral-500 rounded" />
                    <div className="text-base font-semibold line-clamp-2">
                      { refineTitle(page.title) }
                    </div>
                  </div>
                  <PinButton
                    className="absolute top-1 right-1"
                    pinned={ page.pinned }
                    onClick={ (event: MouseEvent<HTMLDivElement>) => pinButtonOnClick(event, page) }
                  />
                </Card>
              </LinkComponent>
            );
          }) }
        </div>
      </Card>
    );
  }

};

import { useEffect, useState } from "react";
import { useRevalidator } from "react-router-dom";

import { useUser } from "routes/dataroutes/UserData";
import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";


const MAX_RETRY_COUNT = 5;
const TIMEOUT_MS = 3000;

export function useSubscriptionSynchronizer() {
  const user = useUser();
  const { activeSubscription } = useActiveSubscription();
  const revalidator = useRevalidator();

  const [ count, setCount ] = useState(0);

  useEffect(() => {
    if (user && activeSubscription) {
      if (user.planSlug === activeSubscription.planSlug) {
        // successfully synced
        setCount(0);
      } else if (count < MAX_RETRY_COUNT) {
        const timeoutId = setTimeout(() => {
          setCount(count => count + 1);
          revalidator.revalidate();
        }, TIMEOUT_MS);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [ user, activeSubscription, revalidator, count, setCount ]);
}

import { ReactNode, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import BorderedPage from "components/BorderedPage";
import CheckedComponent from "components/CheckedComponent";
import PlanCardsList from "v2/components/billing/PlanCardsList";
import SubscriptionManagementCard from "v2/components/billing/SubscriptionManagementCard";
import TaggedText from "components/TaggedText";

import { useToasterFromActionData } from "hooks/useToasterFromActionData";
import { useActiveSubscription } from "v2/hooks/billing/useActiveSubscription";
import { useSubscriptionSynchronizer } from "v2/hooks/billing/useSubscriptionSynchronizer";

import ScrollToHeaderTitle from "misc/ScrollToHeaderTitle";


export default function Billing({ children }: { children?: ReactNode }) {
  useEffect(() => {
    let renewalLayoutInterval: NodeJS.Timer;
    /**
     * interval to make sure that the renewal menu never gets out of the screen
     */
    // @ts-ignore
    if (!renewalLayoutInterval) {
      // @ts-ignore
      renewalLayoutInterval = setInterval(() => {
        // Only if we're not in collapsed view
        if (window.innerWidth >= 768) {
          const menu = document.querySelector('.dyna-layout') as HTMLElement;

          if (menu !== null) {
            let rightOffset = parseInt(window.getComputedStyle(menu).right, 10);

            while (menu.getBoundingClientRect().x < 410) {
              rightOffset -= 10;
              menu.style.right = `${rightOffset}px`;
            }
          }
        }
      }, 200);
    }

    return () => {
      // @ts-ignore
      clearInterval(renewalLayoutInterval);
    }
  });

  const activeSubscriptionData = useActiveSubscription();
  const navigate = useNavigate();

  // display toasters after actions
  useToasterFromActionData();

  // refresh data if database and Chargebee are out of sync
  useSubscriptionSynchronizer();

  useEffect(() => {
    if (activeSubscriptionData?.activeSubscriptionError) {
      navigate("error",
        {
          viewTransition: true,
          state: {
            error: activeSubscriptionData.activeSubscriptionError,
            backgroundLocation: "/v2/billing"
          }
        }
      );
    }
  }, [ activeSubscriptionData?.activeSubscriptionError, navigate ]);


  return (
    <>
    <ScrollToHeaderTitle/>
      <BorderedPage title="Billing">
        <div className="flex flex-col gap-8 pb-8">
          <SubscriptionManagementCard />

          <PlanCardsList />

          <div className="flex justify-start lg:justify-center text-lg lg:text-base">
            <span className="text-left lg:text-center">
              Are you not sure what plan fits best for you? Or do you want to discuss the option of a custom plan?{" "}
              <TaggedText
                message="[Contact&nbsp;us.|contact_sales]"
                className="font-bold text-left lg:text-center"
              />
            </span>
          </div>
          <div className="flex flex-col gap-y-5">
            <div className="flex justify-start lg:justify-center text-lg lg:text-base lg:pt-5">All plans come with</div>

            <div
              className="grid lg:grid-cols-[repeat(auto-fit,minmax(230px,max-content))] auto-rows-[1fr] justify-start lg:justify-around gap-6 text-sm whitespace-nowrap">
              <div className="flex-col">
                <CheckedComponent className="py-2">JS Rendering</CheckedComponent>
                <CheckedComponent className="py-2">Premium Proxies</CheckedComponent>
                <CheckedComponent className="py-2">JSON Auto Parsing</CheckedComponent>
              </div>
              <div className="flex-col">
                <CheckedComponent className="py-2">Rotating Proxy Pools</CheckedComponent>
                <CheckedComponent className="py-2">Custom Header Support</CheckedComponent>
                <CheckedComponent className="py-2">Unlimited Bandwidth</CheckedComponent>
              </div>
              <div className="flex-col">
                <CheckedComponent className="py-2">Automated Retries</CheckedComponent>
                <CheckedComponent className="py-2">Desktop & Mobile User Agents</CheckedComponent>
                <CheckedComponent className="py-2">99.9% Uptime Guarantee</CheckedComponent>
              </div>
              <div className="flex-col">
                <CheckedComponent className="py-2">Custom Session Support</CheckedComponent>
                <CheckedComponent className="py-2">CAPTCHA & Anti-Bot Detection</CheckedComponent>
                <CheckedComponent className="py-2">Professional Support</CheckedComponent>
              </div>
            </div>
          </div>
        </div>
      </BorderedPage>
      <Outlet />
      { children }
    </>
  );
}

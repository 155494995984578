import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  Form,
  useActionData,
  useFetcher,
  useLocation,
  useNavigation,
  useParams,
  useSubmit
} from "react-router-dom";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels
} from "@headlessui/react";
import { RiArrowDropDownLine, RiBankCard2Line, RiPaypalLine } from "@remixicon/react";
import _ from "lodash";
import {
  PayPalButtons,
  PayPalScriptProvider
} from "@paypal/react-paypal-js";


import scraperApi from "api";

import { cx, fmtCurrency } from "utils";
import getDataFromSuccessfulResponse from "utils/getDataFromSuccessfulResponse";
import { isAnnualPlan } from "utils/planUtils";

import Button from "components/Button";
import CardExpiryInput from "components/CreditCardInputs/CardExpiryInput";
import CardNumberInput from "components/CreditCardInputs/CardNumberInput";
import ComboBoxWithValidation from "components/ComboBoxWithValidation";
import CVVInput from "components/CreditCardInputs/CVVInput";
import InputFieldWithValidation from "components/InputFieldWithValidation";
import PriceTag from "components/PriceTag";
import Spinner from "components/Spinner";
import SubmitButton from "components/SubmitButton";
import CouponCodeInput from "v2/components/billing/CouponCodeInput";

import { useDiscountPrice } from "v2/hooks/billing/useDiscountPrice";
import { usePlans } from "v2/hooks/billing/usePlans";
import useNewUpgradeDialog from "v2/hooks/billing/useNewUpgradeDialog";
import useBillingInfo from "v2/hooks/billing/useBillingInfo";

import IRouterActionError, { ActionError } from "routes/dataroutes/IRouterActionError";
import { useUser } from "routes/dataroutes/UserData";
import { Fetchers } from "routes/dataroutes/Fetchers";
import { BillingActions, BillingInputName } from "v2/dataroutes/BillingData";

import Iso3166CountryCodes from "misc/Iso3166CountryCodes";

import { Coupon, CouponError } from "types/Coupons";

import Modal from "./index";


interface IInputProps {
  label: string;
}

function PlanContent() {
  const { planId: targetPlanSlug } = useParams();
  const couponsFetcher = useFetcher<Coupon | CouponError | undefined>(Fetchers.COUPON_FETCHER);
  const { allPlans } = usePlans();
  const targetPlan = allPlans?.find(plan => plan.planSlug === targetPlanSlug);
  const coupon = getDataFromSuccessfulResponse<Coupon>(couponsFetcher.data);
  const actualPrice = useDiscountPrice({ targetPlan, coupon });

  if (!targetPlan) {
    return (
      <div className="flex flex-row items-center p-3 bg-lightestGray-75 dark:bg-neutral-50">
        <div className="flex flex-row items-start gap-x-2">
          <span className="text-gray dark:text-neutral-600 italic">Loading plan data...</span>
          <Spinner className="w-4 h-4 animate-spin text-gray dark:text-neutral-600" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className="flex flex-row justify-between items-center p-3 bg-lightestGray-75 dark:bg-neutral-50">
        <div className="flex flex-col items-start">
          <div className="font-semibold text-lg">{ targetPlan?.planName }</div>
          <div className="text-sm">{ isAnnualPlan(targetPlanSlug) ? "Annual" : "Monthly" } subscription</div>
        </div>
        <div className="flex flex-col items-end">
          <PriceTag
            size="small"
            price={ actualPrice }
            originalPrice={ targetPlan.price }
            period={ targetPlan.billingPeriod || 1 }
            periodUnit={ targetPlan.billingPeriodUnit || "month" }
            isAnnual={ isAnnualPlan(targetPlanSlug) || false }
          />
        </div>
      </div>

      <div className="text-justify">
        By upgrading your subscription, we will reset your credit counters, invoice { fmtCurrency(actualPrice / 100) } now and set your billing date
        to today.
      </div>
    </div>
  );
}

function CouponContent() {
  const couponFetcher = useFetcher(Fetchers.COUPON_FETCHER);
  const { planId: targetPlanSlug } = useParams();

  return (
    <Disclosure>
      { ({ open }) => (
        <>
          <DisclosureButton>
            <div className="flex flex-row text-brandPrimary dark:text-primary-600">
              Have a coupon?
              <RiArrowDropDownLine className={ cx(open && "-rotate-180", "transition-transform duration-300" ) }/>
            </div>
          </DisclosureButton>

          <DisclosurePanel static>
            <div className="flex flex-row items-end w-full">
              <div className={ cx(
                "grid grid-rows-[min-content_min-content] grid-cols-[auto_min-content] grid-flow-col w-full gap-x-3",
                !open && "invisible"
              ) }>
                <CouponCodeInput targetPlanSlug={ targetPlanSlug } fetcher={ couponFetcher } />
              </div>
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}

function ActionButtons({ closeModal }: { closeModal: () => void }) {
  const actionData = useActionData();

  return (
    <div className="flex flex-row justify-end items-center w-full gap-x-3 pt-4">
      <Button text="Cancel" className="button button-tertiary" onClick={ closeModal } />
      <SubmitButton
        text="Upgrade subscription"
        checkFormValidity
        disabled={ actionData === null }
      />
    </div>
  )
}

function AuthorizationMessage() {
  return (
    <div className="text-xs text-gray dark:text-neutral-600">
      I authorize SaaS.group LLC to save this payment method and automatically charge this payment method whenever a
      subscription is associated with it.
    </div>
  );
}

function RenderInputs({ riKey, layout, data, namePrefix, actionError }: { riKey: string | number, layout: any, data: any, namePrefix?: string, actionError?: ActionError }) {
  const [ mutableErrors, setMutableErrors ] = useState(actionError?.formInputErrors);

  useEffect(() => {
    setMutableErrors(actionError?.formInputErrors);
  }, [ actionError?.formInputErrors ]);

  if (Array.isArray(layout)) {
    return (
      <div key={ riKey } className="flex flex-row gap-x-3">
        { layout.map((row: any, index: number) => {
          const newKey = riKey + "_" + index;
          return <RenderInputs key={ newKey } riKey={ newKey } layout={ row } data={ data } namePrefix={ namePrefix } actionError={ actionError } />;
        }) }
      </div>
    );
  } else {
    return (
      <div key={ riKey } className="flex flex-col gap-y-3">
        { Object.entries(layout).map(([ field, subLayout ]) => {
          const newKey = riKey + "_" + field;

          if (_.has(subLayout, "label")) {
            if (_.has(subLayout, "options")) {
              // combo box input
              const comboBoxInputProps = subLayout as IComboBoxInputProps<any>;
              return (
                <ComboBoxWithValidation
                  key={ newKey }
                  name={ _.join([ namePrefix, field ], ".") }
                  options={ comboBoxInputProps.options }
                  inputDisplayValue={ comboBoxInputProps.inputDisplayValue }
                  listValue={ comboBoxInputProps.listValue }
                  required={ data[field]?.validation?.required }
                  isValidated
                  label={ comboBoxInputProps.label }
                  value={ comboBoxInputProps.options.find(option => (comboBoxInputProps.storedValue ? comboBoxInputProps.storedValue(option) : option) === data[field]?.value) }
                />
              );
            } else {
              // simple text input
              const textInputProps = subLayout as IInputProps;
              return (
                <InputFieldWithValidation
                  key={ newKey }
                  type="text"
                  label={ textInputProps.label }
                  name={ _.join([ namePrefix, field ], ".") }
                  value={ data[field]?.value }
                  required={ data[field]?.validation?.required }
                  errorMessage={ mutableErrors ? mutableErrors[field] : undefined }
                  isValidated
                  onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                    event.currentTarget.setCustomValidity("");
                    setMutableErrors(_.omit(mutableErrors, field));
                  } }
                />
              );
            }
          }
          else if (typeof subLayout === "string") {
            return (
              <InputFieldWithValidation
                key={ newKey }
                type="text"
                label={ subLayout }
                name={ _.join([ namePrefix, field ], ".") }
                value={ data[field]?.value }
                required={ data[field]?.validation?.required }
                errorMessage={ mutableErrors ? mutableErrors[field] : undefined }
                isValidated
                onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                  event.currentTarget.setCustomValidity("");
                  setMutableErrors(_.omit(mutableErrors, field));
                }}
              />
            )
          }

          return <RenderInputs key={ newKey } riKey={ newKey } layout={ subLayout } data={ data } namePrefix={ namePrefix } actionError={ actionError } />;
        }) }
      </div>
    )
  }
}

interface IInputLayout {
  [ index: string ]: IInputProps | IComboBoxInputProps<any> | IInputLayout[];
}

interface IComboBoxInputProps<T> extends IInputProps {
  options: T[];
  inputDisplayValue?: (item: T) => string;
  listValue?: (item: T) => string;
  storedValue?: (item: T) => any;
}

function BillingAddressInputs({ billingAddress }: { billingAddress: any }) {
  const layout: IInputLayout = {
    name: [ { first_name: { label: "First name" } }, { last_name: { label: "Last name" } } ],
    line1: { label: "Address" },
    cityAndZip: [ { city: { label: "City" } }, { zip: { label: "ZIP" } } ],
    stateAndCountry: [ { state: { label: "State" } }, {
      country: {
        label: "Country",
        options: Object.values(Iso3166CountryCodes),
        inputDisplayValue: isoCountry => isoCountry?.name,
        listValue: isoCountry => isoCountry?.name,
        storedValue: isoCountry => isoCountry?.countryCode
      }
    } ]
  };

  const actionData = useActionData();
  const billingAddressErrors = (actionData as IRouterActionError | undefined)?.error?.meta?.billingAddressErrors as ActionError | undefined;

  return (
    <RenderInputs riKey="" layout={ layout } data={ billingAddress } namePrefix={ BillingInputName.billingAddress } actionError={ billingAddressErrors } />
  );
}

function CreditCardComponents(
  {
    validate = true,
    errors
  }: {
    validate?: boolean;
    errors?: ActionError;
  }
) {

  const [ mutableErrors, setMutableErrors ] = useState(errors?.formInputErrors);

  useEffect(() => {
    // update mutable errors here in case errors have been changed in a parent component
    // without this, the internal state won't be updated automatically
    setMutableErrors(errors?.formInputErrors);
  }, [ errors ]);


  return (
    <div className="flex flex-col gap-y-3">
      <CardNumberInput
        name={ BillingInputName.paymentSource + ".number" }
        isValidated
        validate={ validate }
        errorMessage={ mutableErrors ? mutableErrors["number"] : undefined }
        onChange={ (event: ChangeEvent<HTMLInputElement>) => {
          event.currentTarget.setCustomValidity("");
          setMutableErrors(errors => _.omit(errors, "number"));
        } }
      />
      <div className="flex flex-row gap-x-3">
        <CardExpiryInput
          name={ BillingInputName.paymentSource + ".expiry" }
          isValidated
          validate={ validate }
          errorMessage={ mutableErrors ? mutableErrors["expiry_year"] || mutableErrors["expiry_month"] : undefined }
          onChange={ (event: ChangeEvent<HTMLInputElement>) => {
            event.currentTarget.setCustomValidity("");
            setMutableErrors(errors => _.omit(errors, "expiry_year", "expiry_month"));
          } }
        />
        <CVVInput
          name={ BillingInputName.paymentSource + ".cvv" }
          isValidated
          validate={ validate }
        />
      </div>
    </div>
  );
}

function PayPalComponents(
  {
    validate = true,
    onApproveBillingAgreement,
  }: {
    validate?: boolean,
    onApproveBillingAgreement?: (token: string | undefined) => void,
  }
) {

  const [ billingAgreementToken, setBillingAgreementToken ] = useState<string | undefined>();

  useEffect(() => {
    onApproveBillingAgreement?.(billingAgreementToken);
  }, [ onApproveBillingAgreement, billingAgreementToken ]);


  return (
    <>
      { validate && ( /* little hack to make the form and the submit button respond to switching between the credit card and paypal tabs. SubmitButton's mutationobserver will kick in here */
        <input type="hidden" name={ BillingInputName.paymentSource + ".billing_agreement_token" } value={ billingAgreementToken || "" } />
      ) }
      <PayPalScriptProvider
        options={ {
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
          disableFunding: "card",
          vault: true,
          intent: "tokenize",
        } }
      >
        <PayPalButtons
          createBillingAgreement={ async () => {
            setBillingAgreementToken(undefined);
            const billingAgreementTokenResponse = await scraperApi.billing.payPal.createBillingAgreementToken();
            return billingAgreementTokenResponse.billingAgreementToken;
          } }
          onApprove={ async (data, actions) => {
            // only update the hidden input with the billing token here
            // later when the user clicks the submit button, we'll pass this token to the backend where we approve the pending billing agreement
            // and also add a paypal-based payment method (using the approved billing agreement id) in chargebee
            setBillingAgreementToken(data.billingToken ?? undefined);
          } }
        />
      </PayPalScriptProvider>
    </>
  );
}

function PaymentMethodInputs(
  {
    payPalContext,
  }: {
    payPalContext?: { setHasPayPalErrors: (agreementError: boolean) => void },
  }
) {
  const actionData = useActionData();
  const errors = (actionData as IRouterActionError | undefined)?.error?.meta?.paymentSourceErrors as ActionError | undefined;

  const showPayPal = Boolean(process.env.REACT_APP_PAYPAL_CLIENT_ID);

  const availableTabs = [ "card", "paypal" ];
  const [ selectedPaymentSource, setSelectedPaymentSource ] = useState(availableTabs[0]);
  const [ approvedBillingAgreementToken, setApprovedBillingAgreementToken ] = useState<string | undefined>();

  useEffect(() => {
    if (payPalContext) {
      payPalContext.setHasPayPalErrors(selectedPaymentSource === "paypal" && !approvedBillingAgreementToken);
    }

  }, [ selectedPaymentSource, payPalContext, approvedBillingAgreementToken ]);


  return (
    <TabGroup onChange={ (index) => setSelectedPaymentSource(availableTabs[index]) }>
      <input type="hidden" name={ BillingInputName.paymentSource + ".type" } value={ selectedPaymentSource } />

      <TabList className="flex flex-row p-1 bg-lightestGray-75 dark:bg-neutral-50 rounded-lg *:text-sm *:font-medium *:text-gray *:dark:text-neutral-600">
        <Tab className="w-full ui-selected:bg-white ui-selected:text-brandPrimary dark:ui-selected:text-primary-600 ui-not-selected:hover:text-gray-800 dark:ui-not-selected:hover:text-neutral-800 transition-colors rounded-md focus:outline-none ui-selected:shadow-sm ui-selected:shadow-lightestGray-200 dark:ui-selected:shadow-neutral-200">
          <div className="flex flex-row w-full items-center justify-center gap-x-2 p-2.5">
            <RiBankCard2Line className="w-[18px] h-[18px]" />
            <span>Credit Card</span>
          </div>
        </Tab>
        { showPayPal && (
          <Tab className="w-full ui-selected:bg-white ui-selected:text-brandPrimary dark:ui-selected:text-primary-600 ui-not-selected:hover:text-gray-800 dark:ui-not-selected:hover:text-neutral-800 transition-colors rounded-md focus:outline-none  ui-selected:shadow-sm ui-selected:shadow-lightestGray-200 dark:ui-selected:shadow-neutral-200">
            <div className="flex flex-row w-full items-center justify-center gap-x-2 p-2.5">
              <RiPaypalLine className="w-[18px] h-[18px]" />
              <span>PayPal</span>
            </div>
          </Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel unmount={ false }>
          <CreditCardComponents errors={ errors } validate={ selectedPaymentSource === "card" } />
        </TabPanel>
        { showPayPal && (
          <TabPanel unmount={ false }>
            <PayPalComponents validate={ selectedPaymentSource === "paypal" } onApproveBillingAgreement={ setApprovedBillingAgreementToken } />
          </TabPanel>
        ) }
      </TabPanels>
    </TabGroup>
  );
}

function BillingInfoForm(
  {
    hasValidBillingAddress,
    billingAddress,
    hasValidPaymentSource,
    paymentSources,
  }: {
    hasValidBillingAddress?: boolean;
    billingAddress: any;
    hasValidPaymentSource?: boolean;
    paymentSources: any;
  }
) {
  const [ hasPayPalErrors, setHasPayPalErrors ] = useState(false);
  const navigation = useNavigation();

  const disableSubmitButton =
    navigation.state !== "idle"
    // showing the paypal controls but the billing agreement has not been approved yet by the user
    || hasPayPalErrors;

  const [ showBillingAddress, setShowBillingAddress ] = useState(hasValidBillingAddress === false);
  useEffect(() => {
    if (hasValidBillingAddress === false) {
      setShowBillingAddress(true);
    }
  }, [ hasValidBillingAddress ]);

  const [ showPaymentSource, setShowPaymentSource ] = useState(hasValidPaymentSource === false);
  useEffect(() => {
    if (hasValidPaymentSource === false) {
      setShowPaymentSource(true);
    }
  }, [ hasValidPaymentSource ]);

  const location = useLocation();


  return (
    <div
      className="flex flex-col w-full h-full gap-y-6"
    >
      { showPaymentSource && (
        <PaymentMethodInputs payPalContext={ { setHasPayPalErrors } }/>
      ) }

      { showBillingAddress && (
        <BillingAddressInputs billingAddress={ billingAddress } />
      ) }

      { showPaymentSource && <AuthorizationMessage /> }
      <div className="flex flex-row items-end h-full">
        <SubmitButton
          text="Upgrade subscription"
          formAction={ location.pathname }
          className="self-end button button-primary"
          fullWidth
          checkFormValidity
          trackDOMChanges
          disabled={ disableSubmitButton }
        />
      </div>
    </div>
  );
}


export default function UpgradeSubscriptionModal() {
  const user = useUser();
  const { useForBillingAddress, useForPaymentSources } = useNewUpgradeDialog();
  const { billingAddress, paymentSources } = useBillingInfo();
  const isLoadingBillingInfo = useMemo(() => (billingAddress.data === undefined) || (paymentSources.data === undefined), [ billingAddress, paymentSources ]);

  const hasToShowBillingForm = useMemo(() => {
    return (useForBillingAddress && (billingAddress.isValid === false)) || (useForPaymentSources && (paymentSources.hasValidPaymentSource === false));
  }, [ useForBillingAddress, billingAddress.isValid, useForPaymentSources, paymentSources.hasValidPaymentSource ]);

  // using an internal state here, so if the form had to be shown once, it will not disappear
  const [ showBillingForm, setShowBillingForm ] = useState(hasToShowBillingForm);
  useEffect(() => {
    if (hasToShowBillingForm) {
      setShowBillingForm(true);
    }
  }, [ hasToShowBillingForm ]);

  const navigation = useNavigation();
  // actionData can hold possible errors here:
  //  {
  //    error: {
  //      meta: {
  //        paymentSourceErrors: ActionError,
  //        billingAddressErrors: ActionError
  //      }
  //    }
  //  }
  const actionData = useActionData();
  const submit = useSubmit();
  const formRef = useRef(null);

  useEffect(() => {
    if ((actionData === "OK") && formRef.current) {
      // billing info part has been successfully submitted. submit the form again to upgrade the subscription
      submit(formRef.current, { viewTransition: true });
    }
  }, [ actionData, submit ]);


  return (
    <Modal
      headline="Upgrade Subscription"
      preventClosingIf={ navigation.state === "submitting" }
      goBackInHistoryOnClose
    >
      { ({ closeModal }) =>
        <Form
          method="POST"
          action="/v2/billing"
          ref={ formRef }
          noValidate
          className={ cx("grid min-h-[490px] p-6 gap-10", hasToShowBillingForm && "lg:grid-cols-[350px,400px]") }
        >
          <input type="hidden" { ...BillingActions.upgrade } />
          <div className="flex flex-col w-full gap-y-6">
            <PlanContent />
            { (user?.canUseAllCoupons || user?.canUseCoupons) && <CouponContent /> }

            { isLoadingBillingInfo && (
              <div className="flex h-full w-full items-end">
                <div className="flex flex-row items-center gap-x-2 text-sm text-gray dark:text-neutral-600">
                  <Spinner className="w-4 h-4 animate-spin" />
                  <div>Checking { (paymentSources.data === undefined) && <>payment method</> }{ (paymentSources.data === undefined && billingAddress.data === undefined) && <> and </>}{ billingAddress.data === undefined && <>billing address</> }...</div>
                </div>
              </div>
            ) }

            { !isLoadingBillingInfo && !showBillingForm && (
              <div className="flex h-full w-full items-end">
                <ActionButtons closeModal={ closeModal } />
              </div>
            )}

          </div>

          { !isLoadingBillingInfo && showBillingForm && (
            <div className="w-full h-full border border-borderColor dark:border-neutral-200 rounded-lg p-6">
              <BillingInfoForm
                hasValidBillingAddress={ billingAddress.isValid }
                billingAddress={ billingAddress.data }
                hasValidPaymentSource={ paymentSources.hasValidPaymentSource }
                paymentSources={ paymentSources.data }
              />
            </div>
          )}
        </Form>
      }
    </Modal>
  );
};
